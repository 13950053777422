import React from "react";
import { Snackbar, Fade, Alert as MuiAlert } from "@mui/material";

const Alert = React.forwardRef(function Alert(props, ref) {
  return (
    <MuiAlert
      elevation={1}
      ref={ref}
      variant="filled"
      {...props}
      sx={{ borderRadius: "12px" }}
    />
  );
});

export default function MainMessage(props) {
  const { component } = props;

  const message = component.getData("message", {
    text: "",
    type: "info",
    duration: 4000,
  });

  const handleClose = () => {
    component.setData({
      "message.text": "",
    });
  };

  // const colors = {
  //   info: "rgb(2, 136, 209)",
  //   success: "#FF6F4D",
  //   error: "rgb(211, 47, 47)",
  //   warning: "rgb(245, 124, 0)",
  // };

  return (
    <Snackbar
      open={message.text !== ""}
      autoHideDuration={message.duration}
      onClose={handleClose}
      TransitionComponent={Fade}
      anchorOrigin={{ horizontal: "center", vertical: "top" }}
      sx={{
        maxWidth: "unset",
        // "& .MuiAlert-root": {
        //   backgroundColor: colors[message.type],
        // },
      }}
    >
      <Alert severity={message.type}>{component.ucfirst(message.text)}</Alert>
    </Snackbar>
  );
}
