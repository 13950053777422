import React, { forwardRef } from "react";
import { useTheme } from "@mui/material/styles";
import {
  Box,
  CardMedia,
  Typography,
  useMediaQuery,
  Tabs,
  Tab,
  ButtonBase,
  Card,
  Button,
} from "@mui/material";
import { Link as RouterLink } from "react-router-dom";

const MyTabScrollButton = forwardRef((props, ref) => {
  const { direction, ...other } = props;

  return (
    <ButtonBase
      component="div"
      ref={ref}
      {...other}
      disableRipple
      aria-label="Change Slide"
    >
      {direction === "left" ? (
        <i className="fa-sharp fa-regular fa-chevron-left" />
      ) : (
        <i className="fa-sharp fa-regular fa-chevron-right" />
      )}
    </ButtonBase>
  );
});

export default function Recipes(props) {
  const { component } = props;
  const theme = useTheme();
  const view = useMediaQuery(theme.breakpoints.up("md")) ? "desktop" : "mobile";

  const devHelper = component.getHelpers("dev");
  const filesUrl = component.getHelpers("env").get("files_url");
  const img_version = component.getHelpers("value").getValue("images-version");
  const recipes = component.getData("default.recipes", []);

  return (
    <Box sx={{ py: 8 }}>
      <Typography
        component="h2"
        variant="h2"
        sx={{
          ...theme.custom.titles.title,
          mb: 2,
          color: "#b52524",
          mx: 4,
        }}
      >
        Our Recipes
      </Typography>
      <Tabs
        value={0}
        variant={"scrollable"}
        scrollButtons="auto"
        ScrollButtonComponent={MyTabScrollButton}
        sx={{
          width: "100%",
          position: "relative",
          justifyContent: "center",
          "& .MuiTabs-indicator": {
            display: "none",
          },
          "& .MuiTabs-scrollButtons:nth-of-type(1)": {
            left: 20,
          },
          "& .MuiTabs-scrollButtons": {
            position: "absolute",
            color: "#fff",
            bgcolor: "#b52524 !important",
            height: "30px",
            width: "30px",
            zIndex: 100,
            top: "50%",
            webkitTransform: "translate(0,-50%)",
            transform: "translate(0,-50%)",
            borderRadius: "50%",
            right: 20,
          },
          "& .MuiTabs-scrollButtons.Mui-disabled": {
            display: "none",
          },
        }}
      >
        {recipes.map((recipe) => {
          const { slug, name } = devHelper.getObjectValue(recipe, "_values");

          return (
            <Tab
              key={slug}
              disableRipple
              className="myTab"
              sx={{
                p: 0,
                px: 2,
                maxWidth: "unset",
                textTransform: "none",
              }}
              component={RouterLink}
              to={`/recipe/${slug}`}
              label={
                <Card
                  className="image_shadow"
                  sx={{
                    my: 2,
                    borderRadius: "20px",
                    position: "relative",
                    width: { xs: 300, sm: 400, md: 500 },
                  }}
                  elevation={3}
                >
                  <CardMedia
                    component="img"
                    alt={`${name}-${view}`}
                    sx={{ maxWidth: "100%" }}
                    image={`${filesUrl}/recipes/${slug}.jpg?v=${img_version}`}
                  />
                  <Typography
                    variant="h5"
                    align="center"
                    sx={{
                      position: "absolute",
                      top: "50%",
                      left: 0,
                      transform: "translate(0, -50%)",
                      fontSize: { xs: 25, sm: 28 },
                      fontWeight: 600,
                      color: "#fff",
                      mb: 2,
                      zIndex: 2,
                      width: "100%",
                      px: 2,
                    }}
                  >
                    {name}
                  </Typography>
                  <Box
                    sx={{
                      position: "absolute",
                      bottom: 16,
                      right: 16,
                      fontSize: 30,
                      color: "#fff",
                      zIndex: 2,
                    }}
                  >
                    <i className="fa-light fa-circle-arrow-up-right" />
                  </Box>
                </Card>
              }
            />
          );
        })}
      </Tabs>
      <Button
        sx={{
          ...theme.custom.buttons.button1,
          width: "220px",
          height: "46px",
          mx: "auto",
          display: "block",
          textAlign: "center",
          mt: 4,
        }}
        component={RouterLink}
        to="/recipes"
      >
        All Recipes
      </Button>
    </Box>
  );
}
