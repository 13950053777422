import { Component } from "../../../modules/Core/Component";
import templateDefault from "../../templates/default/widgets/about_us/about_us";
import Services from "../../Services/Services";

export default class AboutUs extends Component {
  id = "about-us";

  template = templateDefault;

  onLoad(data) {
    super.onLoad(data);

    this.getSlider();
  }

  getSlider() {
    Services.get("hook").then(async ([hookService]) => {
      try {
        var response = await hookService.exec("static", {
          params: { slug: this.props.slug },
        });

        this.setData({
          "default.slider": response.getData(),
        });
      } catch (e) {
        console.log(e);
      }
    });
  }
}
