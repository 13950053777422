import React from "react";
import { useTheme } from "@mui/material/styles";
import {
  Box,
  CardMedia,
  Typography,
  useMediaQuery,
  Card,
  Grid,
  Container,
  Link,
} from "@mui/material";
import { Link as RouterLink } from "react-router-dom";

export default function ProductsSlider(props) {
  const { component } = props;
  const theme = useTheme();
  const view = useMediaQuery(theme.breakpoints.up("md")) ? "desktop" : "mobile";

  const devHelper = component.getHelpers("dev");
  const filesUrl = component.getHelpers("env").get("files_url");
  const img_version = component.getHelpers("value").getValue("images-version");
  const recipes = component.getData("default.recipes", []);

  return (
    <Box sx={{ py: 8 }}>
      <Container>
        <Typography
          component="h2"
          variant="h2"
          align="center"
          sx={{
            ...theme.custom.titles.title,
            mb: 2,
            color: "#b52524",
            mx: 4,
          }}
        >
          Our Recipes
        </Typography>
        <Grid container spacing={2}>
          {recipes.map((recipe) => {
            const { slug, name } = devHelper.getObjectValue(recipe, "_values");

            return (
              <Grid key={slug} item xs={12} sm={6} md={4}>
                <Link component={RouterLink} to={`/recipe/${slug}`}>
                  <Card
                    className="image_shadow"
                    sx={{
                      position: "relative",
                      borderRadius: "20px",
                    }}
                    elevation={3}
                  >
                    <CardMedia
                      component="img"
                      alt={`${name}-${view}`}
                      sx={{ maxWidth: "100%" }}
                      image={`${filesUrl}/recipes/${slug}.jpg?v=${img_version}`}
                    />
                    <Typography
                      variant="h5"
                      align="center"
                      sx={{
                        position: "absolute",
                        top: "50%",
                        left: 0,
                        transform: "translate(0, -50%)",
                        fontSize: { xs: 25, sm: 28 },
                        fontWeight: 600,
                        color: "#fff",
                        mb: 2,
                        zIndex: 2,
                        width: "100%",
                        px: 2,
                      }}
                    >
                      {name}
                    </Typography>
                    <Box
                      sx={{
                        position: "absolute",
                        bottom: 16,
                        right: 16,
                        fontSize: 30,
                        color: "#fff",
                        zIndex: 2,
                      }}
                    >
                      <i className="fa-light fa-circle-arrow-up-right" />
                    </Box>
                  </Card>
                </Link>
              </Grid>
            );
          })}
        </Grid>
      </Container>
    </Box>
  );
}
