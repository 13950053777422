import React from "react";
import { Typography, Container, CardMedia, Box } from "@mui/material";
import Translate from "../../../../../modules/Core/Components/Translate";

export default function AboutUs(props) {
  const { component } = props;
  const { slider } = component.getData("default", {});
  const devHelper = component.getHelpers("dev");
  const img_version = component.getHelpers("value").getValue("images-version");
  const filesUrl = component.getHelpers("env").get("files_url");

  return (
    <Container
      sx={{
        px: 2,
        my: 3,
      }}
      maxWidth="md"
    >
      {slider && (
        <Box>
          <CardMedia
            component="img"
            alt={`${devHelper.getObjectValue(slider, "_values.name")}`}
            sx={{
              width: { xs: "100%", md: "400px" },
              mx: "auto",
              borderRadius: 12,
            }}
            image={`${filesUrl}/sliders/desktop/${devHelper.getObjectValue(
              slider,
              "_values.slug"
            )}.jpg?v=${img_version}`}
          />
          <Box sx={{ p: 2 }}>
            <Typography
              variant="h5"
              align="left"
              sx={{
                fontSize: 20,
                fontWeight: 600,
                color: "#b52524",
              }}
            >
              {devHelper.getObjectValue(slider, "_values.name")}
            </Typography>
            <Typography
              align="left"
              sx={{
                fontSize: 18,
                color: "#000",
                fontWeight: 400,
              }}
            >
              <Translate
                entity="literal"
                eslug={devHelper.getObjectValue(slider, "_values.slug")}
                scope="default"
                slug="default"
                auto
              />
            </Typography>
          </Box>
        </Box>
      )}
    </Container>
  );
}
