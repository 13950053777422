import React from "react";
import {
  Container,
  CardMedia,
  Typography,
  Grid,
  Button,
  Box,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";

export default function FirstSection(props) {
  const { component } = props;
  const devHelper = component.getHelpers("dev");
  const filesUrl = component.getHelpers("env").get("files_url");
  const img_version = component.getHelpers("value").getValue("images-version");
  const theme = useTheme();
  const { recipe = null } = component.getData("default", {});
  const {
    slug = null,
    name = null,
    ingredients = "",
    description = "",
    directions = "",
    prep_time = "",
    cook_time = "",
    total_time = "",
    servings = "",
  } = devHelper.getObjectValue(recipe, "_values", {});

  //css
  const text = {
    fontSize: 18,
    fontWeight: 400,
    color: "#000",
  };

  const text2 = {
    fontSize: 20,
    fontWeight: 700,
    color: "#000",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    borderBottom: "1px solid #000",
    py: 1,
    "& span:first-of-type": { color: "#5d5d5d" },
    "& span:last-child": { color: "#b52524" },
  };

  const title = {
    fontSize: 24,
    color: "#0b6a76",
    fontWeight: 700,
  };

  return (
    <Box>
      {recipe && (
        <Box sx={{ mb: 4 }}>
          <CardMedia
            component="img"
            alt={name}
            className="full-height-image"
            sx={{ maxWidth: "100%", height: { xs: "unset", sm: 400 } }}
            image={`${filesUrl}/recipes/${slug}.jpg?v=${img_version}`}
            onLoad={() => {
              component.setData({ "default.ready": true });
            }}
            onError={() => {
              component.setData({ "default.ready": true });
            }}
          />
          <Container maxWidth="lg" className="recipe">
            <Grid
              container
              rowSpacing={{ xs: 2, md: 2 }}
              columnSpacing={{ xs: 0, md: 4 }}
            >
              {/* product info section */}
              <Grid item xs={12} md={6}>
                <Typography
                  component="h5"
                  dangerouslySetInnerHTML={{ __html: description }}
                  sx={{
                    ...text,
                    "& p:first-of-type": {
                      my: 1,
                      fontSize: 22,
                      color: "#b52524",
                    },
                  }}
                />
                <Button
                  className="exclude-from-print"
                  sx={{
                    ...theme.custom.buttons.button1,
                    width: "100px",
                    height: "46px",
                    float: "right",
                  }}
                  onClick={() => window.print()}
                >
                  Print
                </Button>
              </Grid>
              {/* product info section */}
              <Grid item xs={12} md={6}>
                <Typography component="h5" sx={{ ...text2 }}>
                  <span>Prep Time</span>
                  <span>{prep_time}</span>
                </Typography>
                <Typography component="h5" sx={{ ...text2 }}>
                  <span>Cook Time</span>
                  <span>{cook_time}</span>
                </Typography>
                <Typography component="h5" sx={{ ...text2 }}>
                  <span>Tital Time</span>
                  <span>{total_time}</span>
                </Typography>
                <Typography component="h5" sx={{ ...text2 }}>
                  <span>Serves</span>
                  <span>{servings}</span>
                </Typography>
                <Typography component="h5" sx={{ ...text2 }}>
                  <span>Yield</span>
                  <span>
                    {devHelper.getObjectValue(recipe, "_values.yield", "")}
                  </span>
                </Typography>
              </Grid>
              {/* product ingredients section */}
              <Grid item xs={12} md={6}>
                <Typography component="h3" sx={{ ...title, mt: 2 }}>
                  Ingredients
                </Typography>
                <Typography
                  component="h5"
                  dangerouslySetInnerHTML={{ __html: ingredients }}
                  sx={{
                    ...text,
                    ul: { m: 0, p: 0, listStyleType: "none" },
                    li: { borderBottom: "1px solid #000", py: 1 },
                  }}
                />
              </Grid>
              {/* product directions section */}
              <Grid item xs={12} md={6}>
                <Typography component="h3" sx={{ ...title }}>
                  Cooking Instructions
                </Typography>
                <Typography
                  component="h5"
                  dangerouslySetInnerHTML={{ __html: directions }}
                  sx={{ ...text, ol: { p: 0 } }}
                />
              </Grid>
            </Grid>
          </Container>
        </Box>
      )}
    </Box>
  );
}
