import React from "react";
import { AppBar, Toolbar, useScrollTrigger, Box, Slide } from "@mui/material";
import MainAppbar from "../../../../Components/Widgets/MainAppbar";

function ScrollEvent(props) {
  const { children } = props;

  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 90,
  });

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  );
}

export default function NavBar(props) {
  //css

  const appbar = (
    <AppBar
      position="fixed"
      sx={{
        background: "#0b6a76",
        boxShadow: "none",
        transition: "background 1s",
        webkitTransition: "background 1s",
        zIndex: (theme) => theme.zIndex.drawer + 1,
      }}
    >
      <MainAppbar
        {...{
          ...props,
        }}
      />
    </AppBar>
  );

  return (
    <Box>
      <ScrollEvent {...props}>{appbar}</ScrollEvent>
      <Toolbar id="back-to-top-anchor" sx={{ minHeight: "0.1px !important" }} />
    </Box>
  );
}
