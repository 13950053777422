import { Component } from "../../../modules/Core/Component";
import templateDefault from "../../templates/default/widgets/navbar/main_appbar";
import Services from "../../Services/Services";

export default class Navbar extends Component {
  id = "navbar";
  group = "wishlist,order";

  template = templateDefault;

  onLoad(data) {
    super.onLoad(data);

    this.getAll();
  }

  getAll() {
    Services.get("hook").then(async ([hookService]) => {
      try {
        const hookService0 = await hookService.exec("main-menu", {
          params: {},
        });

        const menus = hookService0.getData() || [];

        const hookService1 = await hookService.exec("categories", {
          params: {},
        });

        const categories = hookService1.getData() || [];

        const hookService2 = await hookService.exec("rec_categories", {
          params: {},
        });
        const rep_categories = hookService2.getData() || [];

        const hookService3 = await hookService.exec("products", {
          params: { pageAmount: 100 },
        });
        const products = hookService3.getData() || {};

        const hookService4 = await hookService.exec("recipes", {
          params: { pageAmount: 100 },
        });
        const recipes = hookService4.getData() || [];

        this.setData({
          "default.mainMenu": this.fixMainMenu(menus),
          "default.categories": categories,
          "default.rep_categories": rep_categories,
          "default.products": products,
          "default.recipes": recipes,
        });
      } catch (e) {
        console.log(e);
      }
    });
  }

  fixMainMenu(menu) {
    menu = menu
      .map((menu) => {
        const { _values = {} } = menu;
        const subMenu = _values["sub-menu"] || [];
        const newSubMenu = {};

        subMenu.forEach((subMenu) => {
          const { type } = subMenu;

          if (!newSubMenu[type]) {
            newSubMenu[type] = [];
          }

          newSubMenu[type].push(subMenu);
        });

        for (var i in newSubMenu) {
          newSubMenu[i] = newSubMenu[i].sort((a, b) => {
            const posA = a.position * 1;
            const posB = b.position * 1;

            return posA - posB;
          });
        }

        _values["subMenu"] = newSubMenu;

        const menu_types = _values["menu_types"] || [];
        menu_types.forEach((type) => {
          const { slug } = type;
          type["has_menu"] = Object.hasOwn(newSubMenu, slug);
        });

        return _values;
      })
      .sort((menu1, menu2) => {
        const pos1 = menu1.position || 0;
        const pos2 = menu2.position || 0;
        return pos1 - pos2;
      });

    return menu;
  }

  toggleMenuDrawer() {
    this.getComponents()
      .findById("menu-drawer")
      .forEach((drawer) => {
        drawer.toggle();
      });
  }

  checkSearch(e) {
    e.preventDefault();
    const { search_term } = this.getData("default", null);
    const devHelper = this.getHelpers("env").getPublicUrl();
    if (search_term && search_term.length > 1) {
      window.location.href = devHelper + "/search/results?term=" + search_term;
    }
  }
}
