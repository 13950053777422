import Page from "../../../modules/Core/Components/Page";
import templateDefault from "../../templates/default/pages/recipe";

export default class Recipe extends Page {
  title = "recipe";

  template = templateDefault;

  onAuth() {
    this.refresh();
  }

  onLoad(data) {
    super.onLoad(data);
    this.scrollToTop();
  }

  onUpdate(prevProps, prevState, snapshot) {
    super.onUpdate(prevProps, prevState, snapshot);

    if (prevProps.location.pathname !== this.props.location.pathname) {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
      this.getComponents().findById("recipe").first().setRecipe();
    }
  }
}
