import { Component } from "../../../../modules/Core/Component";
import templateDefault from "../../../templates/default/widgets/sliders/recipes";
import Services from "../../../Services/Services";

export default class Recipes extends Component {
  template = templateDefault;

  onLoad(data) {
    super.onLoad(data);

    this.getRecipes();
  }

  getRecipes() {
    Services.get("hook").then(async ([hookService]) => {
      try {
        const hookService1 = await hookService.exec("recipes", {
          params: { pageAmount: 100 },
        });
        const recipes = hookService1.getData() || [];

        this.setData({
          "default.recipes": recipes,
        });
      } catch (e) {
        console.log(e);
      }
    });
  }
}
