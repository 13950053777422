import Home from "./Page/Home";
import Product from "./Page/Product";
import Products from "./Page/Products";
import Recipe from "./Page/Recipe";
import Recipes from "./Page/Recipes";
import OurVisionMission from "./Page/OurVisionMission";
import CoreValues from "./Page/CoreValues";
import OurStory from "./Page/OurStory";
import OurPeople from "./Page/OurPeople";

import Redirect from "./Page/Redirect";

const Module = {
  Home,

  Product,
  Products,
  Recipe,
  Recipes,
  Redirect,
  OurVisionMission,
  CoreValues,
  OurStory,
  OurPeople,
};

export default Module;
