import React from "react";
import { styled, useTheme } from "@mui/material/styles";
import {
  Fade,
  CardMedia,
  Radio,
  RadioGroup,
  FormControlLabel,
  Box,
  Link,
  Typography,
  useMediaQuery,
  Button,
} from "@mui/material";
import { Link as RouterLink } from "react-router-dom";

const BpIcon = styled("span")(({ theme }) => ({
  width: "7px",
  height: "7px",
  backgroundColor: "#fff",
  borderRadius: 50,
  opacity: 0.25,
  margin: "0 4px",
  "&:before": {
    display: "block",
    width: "12px",
    height: "12px",
    backgroundImage:
      "linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0))",
    content: '""',
  },
}));

const BpCheckedIcon = styled(BpIcon)(({ theme }) => ({
  opacity: 1,
}));

function MyRadio(props) {
  return (
    <Radio
      sx={{
        px: "2px",
        "&:hover": {
          bgcolor: "transparent",
        },
      }}
      disableRipple
      color="default"
      checkedIcon={<BpCheckedIcon />}
      icon={<BpIcon />}
      {...props}
    />
  );
}

export default function MainBanner(props) {
  const { component } = props;
  const theme = useTheme();
  const devHelper = component.getHelpers("dev");
  const filesUrl = component.getHelpers("env").get("files_url");

  const sliders = component.getData("default.sliders", []);
  const activeSlide = component.getData("default.active_slide", 0);
  const img_version = component.getHelpers("value").getValue("images-version");

  const view = useMediaQuery(theme.breakpoints.up("sm")) ? "desktop" : "mobile";
  const [timeout, setTimeoutt] = React.useState(0);

  React.useEffect(() => {
    setTimeout(() => {
      setTimeoutt(3000);
    }, 400);
  }, []);

  const {
    top = null,
    bottom = null,
    left = null,
    right = null,
    center = null,
    text_color = "#fff",
    titleMaxWidthDesktop = null,
    titleMaxWidthMobile = null,
    textMaxWidthDesktop = null,
    textMaxWidthMobile = null,
    lazyLoading = null,
  } = component.props;

  const text = (slider, f_color, view) => {
    const { slug } = devHelper.getObjectValue(slider, "_values");

    return (
      <Box
        sx={{
          backgroundColor: "transparent",
          display: "flex",
          flexDirection: "column",
          px: { xs: 2, lg: 0 },
          textAlign: "center",
        }}
      >
        {!component.trans(`${slug}-title`).includes("slider") && (
          <Typography
            component="h1"
            sx={{
              color: f_color,
              fontSize: { xs: 28, md: 40 },
              textTransform: "none",
              lineHeight: 1,
              textAlign: "center",
              p: 0,
              maxWidth: {
                xs: titleMaxWidthMobile ? titleMaxWidthMobile : "100%",
                lg: titleMaxWidthDesktop ? titleMaxWidthDesktop : "100%",
              },
              mx: "auto",
            }}
          >
            {component.trans(`${slug}-title`)}
          </Typography>
        )}
        {!component.trans(`${slug}-description`).includes("slider") && (
          <Typography
            component="h2"
            sx={{
              color: f_color,
              fontSize: { xs: 16, md: 22 },
              fontWeight: 350,
              textTransform: "none",
              textAlign: "center",
              my: 3,
              lineHeight: "22px",
              maxWidth: {
                xs: textMaxWidthMobile ? textMaxWidthMobile : "unset",
                lg: textMaxWidthDesktop ? textMaxWidthDesktop : "unset",
              },
              mx: "auto",
            }}
          >
            {component.trans(`${slug}-description`)}
          </Typography>
        )}
        {!component.trans(`${slug}-button`).includes("slider") && (
          <Typography
            component="h3"
            sx={{
              color: "#fff",
              fontWeight: 600,
              mt: 3,
              fontSize: 16,
              p: "8px 16px",
              maxWidth: "200px",
              bgcolor: "#000",
              mx: "auto",
              borderRadius: "4px",
              textTransform: "uppercase",
              "&:hover": {
                opacity: 0.9,
              },
            }}
          >
            {component.trans(`${slug}-button`)}
          </Typography>
        )}
      </Box>
    );
  };

  const arrows = {
    bgcolor: "transparent",
    position: "absolute",
    top: "50%",
    transform: "translate(0,-50%)",
    fontSize: 20,
    color: "#fff",
    p: 0,
    "&:hover": {
      bgcolor: "transparent",
    },
  };

  return (
    <Box>
      {!component.getData("default.imageReady-0", false) && lazyLoading && (
        <Box
          sx={{
            width: "100vw",
            height: "100vh",
            position: "fixed",
            top: 0,
            left: 0,
            zIndex: 1200,
            bgcolor: "#fff",
            display: "flex",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              position: "fixed",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
            }}
          >
            <i
              style={{
                color: "#0f92a3",
                fontSize: 40,
              }}
              className="fa-duotone fa-spinner fa-spin-pulse"
            />
          </Box>
        </Box>
      )}
      <Box
        sx={{
          position: "relative",
        }}
      >
        {sliders.map((slider, index) => {
          const { slug = null, url = null } = devHelper.getObjectValue(
            slider,
            "_values"
          );
          return (
            <Fade key={slug} in={activeSlide === index} timeout={timeout}>
              <Link component={RouterLink} to={url}>
                <CardMedia
                  component="img"
                  sx={{
                    position: index === 0 ? "relative" : "absolute",
                    top: 0,
                    left: 0,
                    zIndex: 0,
                  }}
                  image={`${filesUrl}/sliders/${view}/${slug}.jpg?v=${img_version}`}
                  alt={`${component.trans(slug)} image`}
                  onLoad={() =>
                    component.setData({
                      [`default.imageReady-${index}`]: true,
                    })
                  }
                />
                <Box
                  sx={{
                    position: "absolute",
                    // display: { xs: "none", lg: "block" },
                    top: top ? top : "unset",
                    bottom: bottom ? bottom : "unset",
                    left: left ? left : "unset",
                    right: right ? right : "unset",
                    transform: center ? `translate(${center})` : "unset",
                    width: "100%",
                  }}
                >
                  {text(slider, text_color, "desktop")}
                </Box>
                <Box
                  sx={{
                    position: "absolute",
                    // top: "50%",
                    // left: "50%",
                    // transform: `translate(-50%,-50%)`,
                    bottom: 80,
                    left: 0,
                    width: "100%",
                    textAlign: "center",
                  }}
                >
                  <Typography
                    component="span"
                    sx={{
                      color: "#fff",
                      fontSize: { xs: 16, md: 24 },
                      textTransform: "none",
                      lineHeight: 1,
                      textAlign: "center",
                      px: { xs: 2, md: 4 },
                      py: 1,
                      border: "2px solid #ffcb05",
                      borderRadius: "50px",
                      fontWeight: 600,
                      bgcolor: "#b52524",
                    }}
                  >
                    Tastico is Fantastico!
                  </Typography>
                </Box>
              </Link>
            </Fade>
          );
        })}
        <Button
          sx={{
            ...arrows,
            left: 0,
          }}
          disableRipple
          onClick={(e) => {
            component.startInterval(
              sliders,
              activeSlide - 1 < 0 ? sliders.length - 1 : activeSlide - 1
            );
          }}
        >
          <i className="fa-solid fa-chevron-left" />
        </Button>
        <Button
          sx={{
            ...arrows,
            right: 0,
          }}
          onClick={(e) => {
            component.startInterval(
              sliders,
              sliders.length <= activeSlide + 1 ? 0 : activeSlide + 1
            );
          }}
          disableRipple
        >
          <i className="fa-solid fa-chevron-right" />
        </Button>
        <Box
          sx={{
            backgroundColor: "transparent",
            position: "absolute",
            bottom: 30,
            left: 0,
            width: "100%",
            textAlign: "center",
          }}
        >
          {sliders.length !== 0 && (
            <RadioGroup row value={activeSlide} style={{ display: "block" }}>
              {sliders.map((slider, index) => (
                <FormControlLabel
                  key={"key-" + index}
                  value={index}
                  control={<MyRadio />}
                  onClick={(e) => {
                    component.startInterval(sliders, index);
                  }}
                  id={"radio-" + index}
                  sx={{ mx: 0, "& .MuiButtonBase-root": { p: 0 } }}
                  aria-label={`slider ${index}`}
                />
              ))}
            </RadioGroup>
          )}
        </Box>
      </Box>
    </Box>
  );
}
