import { Component } from "../../../modules/Core/Component";
import templateDefault from "../../templates/default/widgets/products/products";
import Services from "../../Services/Services";

export default class Products extends Component {
  template = templateDefault;

  onLoad(data) {
    super.onLoad(data);

    this.getProducts();
  }

  getProducts() {
    Services.get("hook").then(async ([hookService]) => {
      try {
        const hookService1 = await hookService.exec("products", {
          params: { pageAmount: 1000 },
        });

        const products = hookService1.getData() || {};

        const hookService2 = await hookService.exec("categories", {
          params: {},
        });

        const categories = hookService2.getData() || [];

        this.setData({
          "default.products": products,
          "default.categories": categories,
        });
      } catch (e) {
        console.log(e);
      }
    });
  }
}
