import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Pages from "../Components/Pages";
import CssBaseline from "@mui/material/CssBaseline";
import MainMessage from "../Components/Widgets/MainMessage";
import ContactUs from "../Components/Widgets/Dialogs/ContactUs";

const theme = createTheme({
  components: {
    MuiUseMediaQuery: {
      defaultProps: {
        noSsr: true,
      },
    },
  },
  palette: {
    primary: {
      main: "#000",
    },
    secondary: {
      main: "#000",
    },
  },
  appbar_height: {
    desktop: "100px",
    mobile: "76px",
  },
  custom: {
    titles: {
      title: {
        fontSize: { xs: 30, md: 40 },
        fontWeight: 600,
        color: "#b52524",
      },
      subtitle: {
        fontSize: { xs: 20, sm: 22, md: 25, lg: 30 },
        fontWeight: 600,
        color: "#000",
      },
      description: {
        fontSize: 18,
        color: "#000",
        fontWeight: 400,
      },
    },
    buttons: {
      button1: {
        fontWeight: 600,
        bgcolor: "#b52524",
        color: "#fff",
        border: "2px solid #b52524",
        borderRadius: 50,
        fontSize: { xs: 18, md: 18 },
        width: "150px",
        height: "46px",
        textTransform: "none",
        "&:hover": {
          bgcolor: "#b52524",
          color: "#fff",
        },
      },
      button2: {
        fontWeight: 600,
        borderRadius: 50,
        fontSize: 18,
        textTransform: "none",
        bgcolor: "transparent",
        color: "#fff",
        border: "2px solid #fff",
        "&:hover": { bgcolor: "transparent", color: "#fff" },
      },
      button3: {
        fontWeight: 600,
        borderRadius: 50,
        fontSize: 18,
        textTransform: "none",
        bgcolor: "#fff",
        color: "#b52524",
        border: "2px solid #fff",
        "&:hover": { bgcolor: "#fff", color: "#b52524" },
      },
    },
  },
});

export default function Template(props) {
  const { component } = props;

  return (
    component.isLoaded() && (
      <ThemeProvider theme={theme}>
        <Router>
          <CssBaseline />
          <MainMessage {...props} />
          <ContactUs {...props} />
          <Switch>
            <Route exact path="/" component={Pages.Home} />

            <Route exact path="/product/:slug" component={Pages.Product} />
            <Route exact path="/recipe/:slug" component={Pages.Recipe} />
            <Route exact path="/products" component={Pages.Products} />
            <Route exact path="/recipes" component={Pages.Recipes} />
            <Route
              exact
              path="/our-vision-mission"
              component={Pages.OurVisionMission}
            />
            <Route exact path="/core-values" component={Pages.CoreValues} />
            <Route exact path="/our-people" component={Pages.OurPeople} />
            <Route exact path="/our-story" component={Pages.OurStory} />
            <Route component={Pages.Redirect} />
          </Switch>
        </Router>
      </ThemeProvider>
    )
  );
}
