import React from "react";
import { styled, useTheme } from "@mui/material/styles";
import {
  Button,
  Dialog,
  TextField,
  IconButton,
  useMediaQuery,
  DialogTitle,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  DialogContent,
  Typography,
  Box,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const MyTextField = styled(TextField)(({ theme }) => ({
  marginTop: "8px",
  "& label.Mui-focused": {
    color: "#0b6a76",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {},
    "&:hover fieldset": {
      borderColor: "#0b6a76",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#0b6a76",
    },
  },
}));

const MyFormControl = styled(FormControl)(({ theme }) => ({
  marginTop: "8px !important",
  "& label.Mui-focused": {
    color: "#0b6a76",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {},
    "&:hover fieldset": {
      borderColor: "#0b6a76",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#0b6a76",
    },
  },
}));

export default function ContactUs(props) {
  const { component } = props;
  const { open = false } = component.getData("default", {});

  const theme = useTheme();
  const fullScreen = useMediaQuery(useTheme().breakpoints.down("sm"));

  return (
    <Dialog
      onClose={() => component.togleDialog(false)}
      fullScreen={fullScreen}
      open={open}
    >
      <IconButton
        aria-label="close"
        onClick={() => component.togleDialog(false)}
        sx={{
          position: "absolute",
          right: 16,
          top: 8,
          color: "#000",
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogTitle onClose={() => component.togleDialog(false)}>
        Contact Form
      </DialogTitle>
      <DialogContent sx={{ textAlign: "center" }}>
        <Box component="form" onSubmit={(e) => component.sendRequest(e)}>
          <Grid container rowSpacing={0} columnSpacing={1}>
            <Grid item xs={12}>
              <MyTextField
                variant="outlined"
                margin="normal"
                fullWidth
                label="Full Name *"
                name="name"
                autoComplete="name"
                value={component.getData("default.fields.name", "")}
                onChange={(e) => {
                  component.setData({
                    "default.fields.name": e.target.value,
                  });
                  component.deleteData("error.fields.name");
                }}
                error={component.dataExists("error.fields.name")}
                helperText={
                  component.getData("error.fields.name", [{ message: "" }])[0]
                    .message
                }
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <MyTextField
                variant="outlined"
                margin="normal"
                fullWidth
                label="Email *"
                name="email"
                value={component.getData("default.fields.email", "")}
                onChange={(e) => {
                  component.setData({
                    "default.fields.email": e.target.value,
                  });
                  component.deleteData("error.fields.email");
                }}
                error={component.dataExists("error.fields.email")}
                helperText={
                  component.getData("error.fields.email", [{ message: "" }])[0]
                    .message
                }
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <MyTextField
                variant="outlined"
                margin="normal"
                fullWidth
                label="Phone *"
                name="phone"
                value={component.getData("default.fields.phone", "")}
                onChange={(e) => {
                  component.setData({
                    "default.fields.phone": e.target.value,
                  });
                  component.deleteData("error.fields.phone");
                }}
                error={component.dataExists("error.fields.phone")}
                helperText={
                  component.getData("error.fields.phone", [{ message: "" }])[0]
                    .message
                }
              />
            </Grid>
            <Grid item xs={12}>
              <MyTextField
                variant="outlined"
                margin="normal"
                fullWidth
                label="Company *"
                name="company"
                autoComplete="company"
                value={component.getData("default.fields.company", "")}
                onChange={(e) => {
                  component.setData({
                    "default.fields.company": e.target.value,
                  });
                  component.deleteData("error.fields.company");
                }}
                error={component.dataExists("error.fields.company")}
                helperText={
                  component.getData("error.fields.company", [
                    { message: "" },
                  ])[0].message
                }
              />
            </Grid>
            <Grid item xs={12}>
              <Typography align="left">
                What best represents the industry you are currently in?
              </Typography>
              <MyFormControl
                fullWidth
                sx={{ mt: 2, mb: 1 }}
                error={component.dataExists("error.fields.industry")}
              >
                <InputLabel>Select an option *</InputLabel>
                <Select
                  value={component.getData("default.fields.industry", "")}
                  label="Select an option *"
                  onChange={(e) => {
                    component.setData({
                      "default.fields.industry": e.target.value,
                    });
                    component.deleteData("error.fields.industry");
                  }}
                >
                  {component.getIndustries().map((industry, index) => (
                    <MenuItem key={`industry-${index}`} value={industry}>
                      {industry}
                    </MenuItem>
                  ))}
                </Select>
              </MyFormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <MyFormControl
                fullWidth
                sx={{ mt: 2, mb: 1 }}
                error={component.dataExists("error.fields.country")}
              >
                <InputLabel>Country *</InputLabel>
                <Select
                  value={component.getData("default.fields.country", "")}
                  label="Country *"
                  onChange={(e) => {
                    component.setData({
                      "default.fields.country": e.target.value,
                      "default.fields.state": "",
                    });
                    component.deleteData("error.fields.country");
                  }}
                >
                  {component.getCountries().map((country, index) => (
                    <MenuItem key={`state-${index}`} value={country.name}>
                      {country.name}
                    </MenuItem>
                  ))}
                </Select>
              </MyFormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <MyTextField
                variant="outlined"
                margin="normal"
                fullWidth
                label="City *"
                name="city"
                value={component.getData("default.fields.city", "")}
                onChange={(e) => {
                  component.setData({
                    "default.fields.city": e.target.value,
                  });
                  component.deleteData("error.fields.city");
                }}
                error={component.dataExists("error.fields.city")}
                helperText={
                  component.getData("error.fields.city", [{ message: "" }])[0]
                    .message
                }
              />
            </Grid>

            {component.getData("default.fields.country", "") ===
              "United States" && (
              <Grid item xs={12}>
                <MyFormControl
                  fullWidth
                  sx={{ mt: 2, mb: 1 }}
                  error={component.dataExists("error.fields.state")}
                >
                  <InputLabel>State *</InputLabel>
                  <Select
                    value={component.getData("default.fields.state", "")}
                    label="State *"
                    onChange={(e) => {
                      component.setData({
                        "default.fields.state": e.target.value,
                      });
                      component.deleteData("error.fields.state");
                    }}
                  >
                    {component.getStates().map((state, index) => (
                      <MenuItem key={`state-${index}`} value={state}>
                        {state}
                      </MenuItem>
                    ))}
                  </Select>
                </MyFormControl>
              </Grid>
            )}
            <Grid item xs={12}>
              <MyTextField
                variant="outlined"
                margin="normal"
                fullWidth
                multiline
                rows={3}
                label="Message *"
                name="message"
                value={component.getData("default.fields.message", "")}
                onChange={(e) => {
                  component.setData({
                    "default.fields.message": e.target.value,
                  });
                  component.deleteData("error.fields.message");
                }}
                error={component.dataExists("error.fields.message")}
                helperText={
                  component.getData("error.fields.message", [
                    { message: "" },
                  ])[0].message
                }
              />
            </Grid>
          </Grid>
          <Button
            sx={{
              my: 2,
              ...theme.custom.buttons.button1,
              width: 200,
            }}
            type="submit"
          >
            {component.ucfirst("submit")}
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  );
}
