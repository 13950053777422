import React, { forwardRef } from "react";
import { useTheme } from "@mui/material/styles";
import {
  Box,
  CardMedia,
  Typography,
  useMediaQuery,
  Tabs,
  Tab,
  ButtonBase,
  Card,
  Button,
} from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import Translate from "../../../../../modules/Core/Components/Translate";

const MyTabScrollButton = forwardRef((props, ref) => {
  const { direction, ...other } = props;

  return (
    <ButtonBase
      component="div"
      ref={ref}
      {...other}
      disableRipple
      aria-label="Change Slide"
    >
      {direction === "left" ? (
        <i className="fa-sharp fa-regular fa-chevron-left" />
      ) : (
        <i className="fa-sharp fa-regular fa-chevron-right" />
      )}
    </ButtonBase>
  );
});

export default function WhoWeAre(props) {
  const { component } = props;
  const theme = useTheme();
  const view = useMediaQuery(theme.breakpoints.up("md")) ? "desktop" : "mobile";

  const devHelper = component.getHelpers("dev");
  const filesUrl = component.getHelpers("env").get("files_url");
  const img_version = component.getHelpers("value").getValue("images-version");
  const sliders = component.getData("default.sliders", []);
  const spelBreak = useMediaQuery("(min-width:1225px)");
  const wwaBreak = useMediaQuery("(min-width:1500px)");

  const spellings = [
    { icon: "fa-duotone fa-face-smile-tongue", letter: "T", text: "asty" },
    { icon: "fa-duotone fa-olive-branch", letter: "A", text: "uthentic" },
    { icon: "fa-duotone fa-badge-check", letter: "S", text: "avvy" },
    { icon: "fa-duotone fa-handshake", letter: "T", text: "rustworthy" },
    { icon: "fa-duotone fa-lightbulb-on", letter: "I", text: "nnovative" },
    { icon: "fa-duotone fa-earth-americas", letter: "C", text: "ultural" },
    { icon: "fa-duotone fa-thumbs-up", letter: "O", text: "ptimum" },
  ];

  return (
    <Box>
      <Tabs
        value={0}
        variant={spelBreak ? "fullWidth" : "scrollable"}
        scrollButtons="auto"
        ScrollButtonComponent={MyTabScrollButton}
        sx={{
          width: "100%",
          position: "relative",
          justifyContent: "center",
          "& .MuiTabs-indicator": {
            display: "none",
          },
          "& .MuiTabs-scrollButtons:nth-of-type(1)": {
            left: 20,
          },
          "& .MuiTabs-scrollButtons": {
            position: "absolute",
            color: "#fff",
            bgcolor: "#b52524 !important",
            height: "30px",
            width: "30px",
            zIndex: 100,
            top: "50%",
            webkitTransform: "translate(0,-50%)",
            transform: "translate(0,-50%)",
            borderRadius: "50%",
            right: 20,
          },
          "& .MuiTabs-scrollButtons.Mui-disabled": {
            display: "none",
          },
        }}
      >
        {spellings.map((spelling, i) => {
          const { icon, letter, text } = spelling;

          return (
            <Tab
              key={`spelling-${i}`}
              disableRipple
              className="myTab"
              sx={{
                p: 0,
                my: 8,
                maxWidth: "unset",
                textTransform: "none",
                "& p": {
                  fontSize: { xs: 22, md: 26 },
                  color: "#000",
                  fontWeight: 500,
                },
                "& i": { fontSize: { xs: 25, md: 35 }, color: "#b52524" },
                "& b": {
                  color: "#b52524",
                  fontWeight: 500,
                  fontSize: { xs: 24, md: 28 },
                },
              }}
              label={
                <Box
                  sx={{
                    width: { xs: 130, md: spelBreak ? "unset" : 180 },
                  }}
                >
                  <Typography align="center">
                    <i className={icon} />
                    <br />
                    <b>{letter}</b>
                    {text}
                  </Typography>
                </Box>
              }
            />
          );
        })}
      </Tabs>
      <Box sx={{ py: 6 }}>
        <Typography
          align="center"
          component="h2"
          variant="h2"
          sx={{
            ...theme.custom.titles.title,
            mb: 2,
            color: "#b52524",
          }}
        >
          We are Tastico
        </Typography>
        <Tabs
          value={0}
          variant={wwaBreak ? "fullWidth" : "scrollable"}
          scrollButtons="auto"
          ScrollButtonComponent={MyTabScrollButton}
          sx={{
            width: "100%",
            position: "relative",
            justifyContent: "center",
            "& .MuiTabs-indicator": {
              display: "none",
            },
            "& .MuiTabs-scrollButtons:nth-of-type(1)": {
              left: 20,
            },
            "& .MuiTabs-scrollButtons": {
              position: "absolute",
              color: "#fff",
              bgcolor: "#b52524 !important",
              height: "30px",
              width: "30px",
              zIndex: 100,
              top: "50%",
              webkitTransform: "translate(0,-50%)",
              transform: "translate(0,-50%)",
              borderRadius: "50%",
              right: 20,
            },
            "& .MuiTabs-scrollButtons.Mui-disabled": {
              display: "none",
            },
          }}
        >
          {sliders.map((slider) => {
            const { slug, url, name } = devHelper.getObjectValue(
              slider,
              "_values"
            );

            return (
              <Tab
                key={slug}
                disableRipple
                className="myTab"
                sx={{
                  p: 0,
                  px: 2,
                  maxWidth: "unset",
                  textTransform: "none",
                }}
                component={RouterLink}
                to={url}
                label={
                  <Card
                    sx={{
                      my: 2,
                      borderRadius: "20px",
                      width: { xs: 300, sm: 345 },
                      height: "100%",
                    }}
                    elevation={3}
                  >
                    <CardMedia
                      component="img"
                      alt={`${name}-${view}`}
                      sx={{ height: 220 }}
                      image={`${filesUrl}/sliders/desktop/${slug}.jpg?v=${img_version}`}
                    />
                    <Box sx={{ p: 2 }}>
                      <Typography
                        variant="h5"
                        align="left"
                        sx={{
                          fontSize: 20,
                          fontWeight: 600,
                          color: "#b52524",
                        }}
                      >
                        {name}
                      </Typography>
                      <Typography
                        className="paragraph"
                        align="left"
                        sx={{
                          fontSize: 18,
                          color: "#000",
                          fontWeight: 400,
                          maxHeight: 99,
                          overflowY: "hidden",
                        }}
                      >
                        <Translate
                          entity="literal"
                          eslug={slug}
                          scope="default"
                          slug="default"
                          auto
                        />
                      </Typography>
                      <Button
                        sx={{
                          ...theme.custom.buttons.button1,
                          width: "140px",
                          mx: "auto",
                          display: "block",
                          mt: 2,
                          fontSize: 16,
                          height: "unset",
                        }}
                      >
                        Read more
                      </Button>
                    </Box>
                  </Card>
                }
              />
            );
          })}
        </Tabs>
      </Box>
    </Box>
  );
}
