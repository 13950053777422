import React from "react";
import { styled, useTheme } from "@mui/material/styles";
import {
  Container,
  Box,
  CardMedia,
  Typography,
  Radio,
  RadioGroup,
  FormControlLabel,
  Grid,
  // Link,
  // IconButton,
  Tabs,
  Tab,
  Fade,
  useMediaQuery,
} from "@mui/material";
import SwipeableViews from "react-swipeable-views";

const BpIcon = styled("span")(({ theme }) => ({
  width: "40px",
  height: "2px",
  backgroundColor: "#d7d8d9",
  [theme.breakpoints.down("sm")]: {
    width: "30px",
  },
  "&:before": {
    display: "block",
    width: "12px",
    height: "12px",
    backgroundImage:
      "linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0))",
    content: '""',
  },
}));

const BpCheckedIcon = styled(BpIcon)(({ theme }) => ({
  width: "40px",
  height: "2px",
  backgroundColor: "#000",
  [theme.breakpoints.down("sm")]: {
    width: "30px",
  },
}));

function MyRadio(props) {
  return (
    <Radio
      sx={{
        px: "2px",
        "&:hover": {
          bgcolor: "transparent",
        },
      }}
      disableRipple
      color="default"
      checkedIcon={<BpCheckedIcon />}
      icon={<BpIcon />}
      {...props}
    />
  );
}

// const BackNextButton = styled(IconButton)(({ theme }) => ({
//   position: "absolute",
//   top: "50%",
//   transform: "translateY(-50%)",
//   borderRadius: "50%",
//   backgroundColor: "#fff",
//   color: "#000",
//   fontSize: "20px",
//   zIndex: 1,
//   width: "35px",
//   height: "35px",
//   justifyContent: "center",
//   alignItems: "center",
//   "&:disabled": {
//     pointerEvents: "all",
//     backgroundColor: "#fff",
//     color: "#000",
//     opacity: "0.5",
//   },
//   "&:hover": {
//     backgroundColor: "#fff",
//   },
// }));

// const LinkContact = styled(Link)(({ theme }) => ({
//   textDecoration: "none",
//   color: "#b52524",
//   fontWeight: 600,
//   display: "flex",
//   alignItems: "center",
//   fontSize: "18px",
//   cursor: "pointer",
//   "& i": {
//     minWidth: 35,
//     fontSize: "20px",
//   },
//   "& div": {
//     textAlign: "center",
//     display: "inline-flex",
//   },
//   "&:hover": {
//     color: "#b52524",
//   },
// }));

const StyledTab = styled((props) => <Tab disableRipple {...props} />)(
  ({ theme }) => ({
    textTransform: "none",
    padding: "0px 8px 8px",
    margin: "0px 4px",
    "&.Mui-selected": {
      color: "#fff",
    },
    "&.Mui-focusVisible": {
      backgroundColor: "rgba(100, 95, 228, 0.32)",
    },
  })
);

export default function FirstSection(props) {
  const { component } = props;
  const theme = useTheme();
  const devHelper = component.getHelpers("dev");
  const filesUrl = component.getHelpers("env").get("files_url");

  const view = useMediaQuery(theme.breakpoints.up("md"));

  const [activeImage, setActiveImage] = React.useState(0);

  const {
    images = [],
    ready = false,
    product = null,
  } = component.getData("default", {});

  const {
    slug = null,
    name = null,
    // types = [],
    // brands = [],
    // categories = [],
    // recipes = [],
    description = "",
  } = devHelper.getObjectValue(product, "_values", {});

  //Change slider image
  // const handleNext = () => {
  //   setActiveImage((prevActiveStep) => prevActiveStep + 1);
  // };

  // const handleBack = () => {
  //   setActiveImage((prevActiveStep) => prevActiveStep - 1);
  // };

  const handleImageChange = (step) => {
    setActiveImage(step);
  };

  const handleImageChange2 = (event, newValue) => {
    setActiveImage(newValue);
  };

  //css

  return (
    <Container maxWidth="lg" disableGutters sx={{ my: 2 }}>
      <Grid
        container
        direction="row"
        sx={{
          alignItems: "stretch",
        }}
        rowSpacing={{ xs: 2, md: 2 }}
        columnSpacing={{ xs: 0, md: 2 }}
      >
        {/* images section */}
        <Grid item xs={12} md={6}>
          <Fade in={ready}>
            <Box>
              <Box sx={{ position: "relative" }}>
                {images.length !== 0 && (
                  <>
                    {/* <BackNextButton
                      sx={{
                        left: 16,
                        display: { xs: "none", md: "flex" },
                      }}
                      onClick={handleBack}
                      disabled={activeImage === 0}
                    >
                      <i className="fa-solid fa-chevron-left" />
                    </BackNextButton>
                    <BackNextButton
                      sx={{
                        right: 16,
                        display: { xs: "none", md: "flex" },
                      }}
                      onClick={handleNext}
                      disabled={activeImage === images.length - 1}
                    >
                      <i className="fa-solid fa-chevron-right" />
                    </BackNextButton> */}
                    <Box
                      sx={{
                        position: "absolute",
                        bottom: 0,
                        left: 0,
                        zIndex: 1,
                        width: "100%",
                        textAlign: "center",
                        display: { xs: "block", md: "none" },
                      }}
                    >
                      <RadioGroup
                        row
                        value={activeImage}
                        sx={{ display: "block" }}
                      >
                        {images.map((image, index) => (
                          <FormControlLabel
                            key={`radio-btn-${index}`}
                            value={index}
                            control={<MyRadio />}
                            onClick={(e) => {
                              handleImageChange(index);
                            }}
                            sx={{ m: 0 }}
                          />
                        ))}
                      </RadioGroup>
                    </Box>
                  </>
                )}
                <SwipeableViews
                  axis={theme.direction === "rtl" ? "x-reverse" : "x"}
                  index={activeImage}
                  onChangeIndex={handleImageChange}
                  enableMouseEvents
                >
                  {images.map((image, index) => (
                    <CardMedia
                      key={"product-img-" + index}
                      sx={{
                        maxWidth: "100%",
                        objectFit: "contain",
                        cursor: "pointer",
                        height: { xs: "250px", sm: "350px", md: "450px" },
                        border: { xs: "unset", md: "1px solid #fff" },
                      }}
                      component="img"
                      image={filesUrl.concat(
                        `/products/images/${slug}/${image}`
                      )}
                      alt={`${name} image`}
                      onLoad={() => {
                        component.setData({ "default.ready": true });
                      }}
                      onError={() => {
                        component.setData({ "default.ready": true });
                      }}
                      onClick={() => component.toggleZoom(images, activeImage)}
                    />
                  ))}
                </SwipeableViews>
              </Box>
              {images.length !== 0 && view && (
                <Tabs
                  value={activeImage}
                  onChange={handleImageChange2}
                  variant="scrollable"
                  scrollButtons={false}
                  sx={{
                    display: "flex",
                    padding: "4px 0px",
                    "& .MuiTabs-indicator": {
                      backgroundColor: "#f05d32 !important",
                      height: "2px",
                    },
                    "& .MuiTabScrollButton-root": {
                      color: "#b52524",
                    },
                    "& .MuiSvgIcon-fontSizeSmall": {
                      fontSize: "40px",
                    },
                  }}
                >
                  {images.map((image, index) => (
                    <StyledTab
                      key={"product-img-" + index}
                      disableRipple
                      label={
                        <CardMedia
                          sx={{
                            width: { xs: "150px" },
                            height: { xs: "73.2px" },
                            objectFit: "contain",
                          }}
                          component="img"
                          image={filesUrl.concat(
                            `/products/images/${slug}/${image}`
                          )}
                          alt={`${name} image`}
                        />
                      }
                    ></StyledTab>
                  ))}
                </Tabs>
              )}
            </Box>
          </Fade>
        </Grid>
        {/* product info section */}
        <Grid item xs={12} md={6}>
          {product && (
            <Box
              sx={{
                p: 2,
                height: "100%",
                borderRadius: { xs: 0, md: "12px" },
                bgcolor: "#f7f8fa",
                border: "1px solid #e4e5e7",
                position: "relative",
              }}
            >
              {/* <Box sx={{ position: "absolute", top: 16, right: 16 }}>
                <IconButton
                  disableRipple
                  sx={{ color: "#000", fontSize: 20 }}
                  onClick={() => {
                    component.shareProduct();
                  }}
                >
                  <i className="fa-light fa-arrow-up-from-bracket" />
                </IconButton>
              </Box> */}
              {/* <Typography
                component="h3"
                sx={{ fontSize: 22, color: "#000", fontWeight: 500, mb: 1 }}
              >
                {name}
              </Typography>
              {categories.length && (
                <Box>
                  <Typography
                    component="h3"
                    sx={{ fontSize: 18, color: "#000", fontWeight: 500 }}
                  >
                    Categories
                  </Typography>
                  <Typography
                    component="h3"
                    sx={{ fontSize: 16, color: "#000", mb: 1 }}
                  >
                    {categories.map((cat) => {
                      const { slug, name } = devHelper.getObjectValue(
                        cat,
                        "item._values"
                      );
                      return <span key={slug}>{name}</span>;
                    })}
                  </Typography>
                </Box>
              )} */}
              <Typography
                component="h5"
                dangerouslySetInnerHTML={{ __html: description }}
              />
              {/* <Box
                sx={{
                  flexDirection: "column",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  borderRadius: "12px",
                  bgcolor: "#fff",
                  border: "1px solid #e4e5e7",
                  p: 2,
                  maxWidth: "300px",
                  mx: "auto",
                  mt: 4,
                }}
              >
                <LinkContact
                  onClick={() =>
                    component
                      .getComponents()
                      .findById("contact-us")
                      .first()
                      .togleDialog(true)
                  }
                >
                  <Box>
                    <i className="fa-light fa-comments-question-check" />
                  </Box>
                  Contact Form
                </LinkContact>
                <LinkContact
                  sx={{ mt: 2 }}
                  href={"tel:" + component.trans("phone-number")}
                >
                  <Box>
                    <i className="fa-light fa-phone" />
                  </Box>
                  {component.trans("phone-number")}
                </LinkContact>
              </Box> */}
            </Box>
          )}
        </Grid>
      </Grid>
    </Container>
  );
}
