import React, { forwardRef } from "react";
import { useTheme } from "@mui/material/styles";
import {
  Box,
  CardMedia,
  Typography,
  useMediaQuery,
  Tabs,
  Tab,
  ButtonBase,
  Card,
  CardContent,
  Button,
} from "@mui/material";
import { Link as RouterLink } from "react-router-dom";

const MyTabScrollButton = forwardRef((props, ref) => {
  const { direction, ...other } = props;

  return (
    <ButtonBase
      component="div"
      ref={ref}
      {...other}
      disableRipple
      aria-label="Change Slide"
    >
      {direction === "left" ? (
        <i className="fa-sharp fa-regular fa-chevron-left" />
      ) : (
        <i className="fa-sharp fa-regular fa-chevron-right" />
      )}
    </ButtonBase>
  );
});

export default function ProductsSlider(props) {
  const { component } = props;
  const theme = useTheme();
  const view = useMediaQuery(theme.breakpoints.up("md")) ? "desktop" : "mobile";

  const devHelper = component.getHelpers("dev");
  const filesUrl = component.getHelpers("env").get("files_url");
  const img_version = component.getHelpers("value").getValue("images-version");
  const products = component.getData("default.products", {});

  return (
    <Box sx={{ py: 6, bgcolor: "#b52524" }}>
      <Typography
        component="h2"
        variant="h2"
        align="center"
        sx={{
          ...theme.custom.titles.title,
          mb: 2,
          color: "#fff",
          mx: 4,
        }}
      >
        Our Products
      </Typography>
      <Tabs
        value={0}
        variant={"scrollable"}
        scrollButtons="auto"
        ScrollButtonComponent={MyTabScrollButton}
        sx={{
          width: "100%",
          position: "relative",
          justifyContent: "center",
          "& .MuiTabs-indicator": {
            display: "none",
          },
          "& .MuiTabs-scrollButtons:nth-of-type(1)": {
            left: 20,
          },
          "& .MuiTabs-scrollButtons": {
            position: "absolute",
            color: "#b52524",
            bgcolor: "#fff !important",
            height: "30px",
            width: "30px",
            zIndex: 100,
            top: "50%",
            webkitTransform: "translate(0,-50%)",
            transform: "translate(0,-50%)",
            borderRadius: "50%",
            right: 20,
          },
          "& .MuiTabs-scrollButtons.Mui-disabled": {
            display: "none",
          },
        }}
      >
        {devHelper.getObjectValue(products, "data", []).map((product) => {
          const { slug, name } = devHelper.getObjectValue(product, "_values");

          return (
            <Tab
              key={slug}
              disableRipple
              className="myTab"
              sx={{
                p: 0,
                px: 2,
                maxWidth: "unset",
                textTransform: "none",
              }}
              component={RouterLink}
              to={`/product/${slug}`}
              label={
                <Card
                  sx={{
                    mt: 1,
                    borderRadius: "20px",
                    height: "100%",
                    position: "relative",
                    bgcolor: "transparent",
                    width: { xs: 300, sm: 400, md: 500 },
                  }}
                  elevation={0}
                >
                  <CardMedia
                    component="img"
                    alt={`${name}-${view}`}
                    sx={{
                      maxWidth: "100%",
                      borderRadius: "20px",
                    }}
                    image={`${filesUrl}/products/images/${slug}/1.jpg?v=${img_version}`}
                  />
                  <CardContent sx={{ pb: "0px !important" }}>
                    <Typography
                      variant="h5"
                      align="center"
                      sx={{
                        fontSize: { xs: 25, sm: 28 },
                        fontWeight: 600,
                        color: "#fff",
                      }}
                    >
                      {name}
                    </Typography>
                  </CardContent>
                </Card>
              }
            />
          );
        })}
      </Tabs>
      <Button
        sx={{
          ...theme.custom.buttons.button3,
          width: "220px",
          height: "46px",
          mx: "auto",
          display: "block",
          textAlign: "center",
          mt: 2,
        }}
        component={RouterLink}
        to="/products"
      >
        All Products
      </Button>
    </Box>
  );
}
