import React from "react";
import {
  Toolbar,
  Link,
  CardMedia,
  Button,
  Box,
  Grid,
  Container,
  Fade,
  Typography,
  IconButton,
} from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import { useTheme } from "@mui/material/styles";

export default function MainAppbar(props) {
  const { component } = props;

  const theme = useTheme();

  const devHelper = component.getHelpers("dev");

  const [openMenu, setOpenMenu] = React.useState(false);
  const [menu, setMenu] = React.useState("products");

  const [menuEffect, setMenuEffect] = React.useState(false);

  const {
    mainMenu = [],
    categories = [],
    rep_categories = [],
    products = {},
    recipes = [],
  } = component.getData("default", {});

  const handleToggle = (boolean, new_menu) => {
    setOpenMenu(boolean);
    if (new_menu !== undefined) {
      setMenu(new_menu);
    }
  };

  React.useEffect(() => {
    if (mainMenu.length) {
      setTimeout(() => {
        setMenuEffect(true);
      }, 500);
    }
  }, [mainMenu.length]);

  //css

  const mainButton = {
    fontSize: 16,
    lineHeight: "16px",
    p: 0,
    my: 0,
    mx: 4,
    borderBottom: "2px solid transparent",
    borderRadius: 0,
    fontWeight: 600,
    color: "#fff",
    "&:hover,&:focus": {
      backgroundColor: "transparent",
      borderBottom: "2px solid #fff",
    },
  };

  const myTitle = {
    color: "#fff",
    fontSize: 18,
    fontWeight: 500,
  };

  const topMenu = {
    position: "absolute",
    left: 0,
    zIndex: 3000,
    width: "100%",
    backgroundColor: "#073f46",
    py: 2,
  };

  const myLink = {
    color: "#fff",
    fontSize: 16,
    textTransform: "none",
    textDecoration: "none",
    fontWeight: 500,
    display: "block",
    mt: 1,
    maxWidth: 250,
    "&:hover": {
      backgroundColor: "transparent",
      opacity: 0.9,
    },
  };

  const drawerButton = {
    minWidth: "unset",
    fontSize: 22,
    color: "#fff",
    "&:hover": {
      backgroundColor: "transparent",
    },
  };

  return (
    <>
      <Toolbar disableGutters>
        <Container
          maxWidth="xl"
          disableGutters
          sx={{
            display: "flex",
            px: { xs: 2, md: 3 },
            justifyContent: { xs: "space-between", md: "center" },
            alignItems: "center",
            height: {
              xs: theme.appbar_height.mobile,
              md: theme.appbar_height.desktop,
            },
          }}
        >
          <Box sx={{ display: { xs: "none", md: "flex" } }}>
            {mainMenu.slice(0, mainMenu.length / 2).map((menu) => {
              const { slug, name, url, menu_template } = menu;
              return (
                <Box
                  key={`menu-btn-${slug}`}
                  onMouseEnter={() => {
                    if (menuEffect) {
                      handleToggle(true, menu_template);
                    }
                  }}
                  onMouseLeave={() => handleToggle(false, menu_template)}
                  onClick={() => handleToggle(false, menu_template)}
                  sx={{
                    height: theme.appbar_height.desktop,
                    display: "flex",
                    alignItems: "center",
                    "&:hover,&:focus": {
                      "& a": {
                        borderBottom: "2px solid #fff",
                      },
                    },
                  }}
                >
                  <Button
                    to={url}
                    component={RouterLink}
                    key={`main-menu-root-${slug}`}
                    sx={{ ...mainButton }}
                    disableRipple
                  >
                    {name}
                  </Button>
                </Box>
              );
            })}
          </Box>
          <Button
            sx={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              width: { xs: "100px", md: "130px" },
              outline: "none",
              p: 0,
              mx: 2,
              "&:hover": {
                bgcolor: "transparent",
              },
            }}
            disableRipple
            component={RouterLink}
            to="/"
          >
            <CardMedia
              component="img"
              image="/images/logos/logo.png"
              alt="Tastico Logo"
              title="Tastico Home"
              sx={{
                maxWidth: "100%",
                width: "inherit",
              }}
            />
          </Button>
          <Box sx={{ display: { xs: "none", md: "flex" } }}>
            {mainMenu.slice(mainMenu.length / 2).map((menu) => {
              const { slug, name, menu_template } = menu;
              return (
                <Box
                  key={`menu-btn-${slug}`}
                  onMouseEnter={() => {
                    if (menuEffect && slug !== "contact-us") {
                      handleToggle(true, menu_template);
                    }
                  }}
                  onMouseLeave={() => {
                    if (menuEffect && slug !== "contact-us") {
                      handleToggle(false, menu_template);
                    }
                  }}
                  onClick={() => {
                    if (menuEffect && slug !== "contact-us") {
                      handleToggle(false, menu_template);
                    }
                  }}
                  sx={{
                    height: theme.appbar_height.desktop,
                    display: "flex",
                    alignItems: "center",
                    "&:hover,&:focus": {
                      "& a": {
                        borderBottom: "2px solid #fff",
                      },
                    },
                  }}
                >
                  <Button
                    key={`main-menu-root-${slug}`}
                    sx={{ ...mainButton }}
                    disableRipple
                    onClick={() => {
                      if (slug === "contact-us") {
                        component
                          .getComponents()
                          .findById("contact-us")
                          .first()
                          .togleDialog(true);
                      }
                    }}
                  >
                    {name}
                  </Button>
                </Box>
              );
            })}
          </Box>
          <Box sx={{ display: { xs: "flex", md: "none" } }}>
            <IconButton
              disableRipple
              onClick={() => {
                component.toggleMenuDrawer();
              }}
              sx={{ ...drawerButton }}
              aria-label="Mobile Menu"
            >
              <i className="fa-solid fa-bars" />
            </IconButton>
          </Box>
        </Container>
      </Toolbar>
      <Fade in={openMenu} timeout={{ enter: 500, exit: 0 }}>
        <Box sx={{ position: "relative" }}>
          {menu === "products" && (
            <Box
              sx={{ ...topMenu }}
              onMouseEnter={() => {
                handleToggle(true);
              }}
              onMouseLeave={() => {
                handleToggle(false);
              }}
            >
              <Container maxWidth="xl" disableGutters>
                <Grid
                  container
                  sx={{
                    py: 2,
                    justifyContent: "center",
                  }}
                >
                  {categories.map((cat, i) => {
                    const { name, slug } = devHelper.getObjectValue(
                      cat,
                      "_values"
                    );

                    return (
                      <Grid item key={slug} sx={{ mx: 1.5 }}>
                        <Typography component="h2" sx={{ ...myTitle }}>
                          {name}
                        </Typography>
                        <Box
                          sx={{
                            width: 35,
                            height: 2,
                            bgcolor: "#fff",
                            mb: 1,
                          }}
                        />
                        <ul
                          style={{
                            margin: "0px",
                            padding: "0px",
                            listStyleType: "none",
                          }}
                        >
                          {devHelper
                            .getObjectValue(products, "data")
                            .filter((pr) =>
                              pr._values.categories.some(
                                (cat) => cat.item._values.slug === slug
                              )
                            )
                            .map((pr, index) => {
                              const { slug, name } = devHelper.getObjectValue(
                                pr,
                                "_values"
                              );
                              return (
                                <li key={`product-${slug}`}>
                                  <Link
                                    component={RouterLink}
                                    to={`/product/${slug}`}
                                    sx={{
                                      ...myLink,
                                    }}
                                    onClick={() => handleToggle(false)}
                                  >
                                    {name}
                                  </Link>
                                </li>
                              );
                            })}
                        </ul>
                      </Grid>
                    );
                  })}
                </Grid>
              </Container>
            </Box>
          )}
          {menu === "recipes" && (
            <Box
              sx={{ ...topMenu }}
              onMouseEnter={() => {
                handleToggle(true);
              }}
              onMouseLeave={() => {
                handleToggle(false);
              }}
            >
              <Container maxWidth="xl" disableGutters>
                <Grid
                  container
                  sx={{
                    py: 2,
                    justifyContent: "center",
                  }}
                >
                  {rep_categories.map((cat, i) => {
                    const { name, slug } = devHelper.getObjectValue(
                      cat,
                      "_values"
                    );

                    return (
                      <Grid item key={slug} sx={{ mx: 2 }}>
                        <Typography component="h2" sx={{ ...myTitle }}>
                          {name}
                        </Typography>
                        <Box
                          sx={{
                            width: 35,
                            height: 2,
                            bgcolor: "#fff",
                            mb: 1,
                          }}
                        />
                        <ul
                          style={{
                            margin: "0px",
                            padding: "0px",
                            listStyleType: "none",
                          }}
                        >
                          {recipes
                            .filter((rec) =>
                              rec._values.rec_categories.some(
                                (cat) => cat.item._values.slug === slug
                              )
                            )
                            .map((rec, index) => {
                              const { slug, name } = devHelper.getObjectValue(
                                rec,
                                "_values"
                              );
                              return (
                                <li key={`recipe-${slug}`}>
                                  <Link
                                    component={RouterLink}
                                    to={`/recipe/${slug}`}
                                    sx={{
                                      ...myLink,
                                    }}
                                    onClick={() => handleToggle(false)}
                                  >
                                    {name}
                                  </Link>
                                </li>
                              );
                            })}
                        </ul>
                      </Grid>
                    );
                  })}
                </Grid>
              </Container>
            </Box>
          )}
          {menu === "about-us" && (
            <Box
              sx={{ ...topMenu }}
              onMouseEnter={() => {
                handleToggle(true);
              }}
              onMouseLeave={() => {
                handleToggle(false);
              }}
            >
              <Container maxWidth="xl" disableGutters>
                <Grid
                  container
                  sx={{
                    py: 2,
                    justifyContent: "center",
                    "& div": {
                      mr: 6,
                    },
                    "& div:last-child": {
                      mr: 0,
                    },
                  }}
                >
                  {mainMenu
                    .find((menu) => menu.slug === "about-us")
                    ["sub-menu"].map((menu, i) => {
                      const { title, url, slug } = menu;

                      return (
                        <Grid item key={slug}>
                          <Link
                            component={RouterLink}
                            to={url}
                            sx={{
                              ...myLink,
                              fontWeight: 600,
                            }}
                            onClick={() => handleToggle(false)}
                          >
                            {title}
                          </Link>
                        </Grid>
                      );
                    })}
                </Grid>
              </Container>
            </Box>
          )}
        </Box>
      </Fade>
    </>
  );
}
