import { Component } from "../../../../modules/Core/Component";
import templateDefault from "../../../templates/default/widgets/sliders/products";
import Services from "../../../Services/Services";

export default class Recipes extends Component {
  template = templateDefault;

  onLoad(data) {
    super.onLoad(data);

    this.getProducts();
  }

  getProducts() {
    Services.get("hook").then(async ([hookService]) => {
      try {
        const hookService1 = await hookService.exec("products", {
          params: { pageAmount: 100, sortBy: "category,asc" },
        });
        const products = hookService1.getData() || {};

        this.setData({
          "default.products": products,
        });
      } catch (e) {
        console.log(e);
      }
    });
  }
}
