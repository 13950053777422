import React from "react";
import { styled } from "@mui/material/styles";
import { useTheme } from "@mui/material/styles";
import {
  Typography,
  Container,
  AccordionDetails,
  AccordionSummary as MuiAccordionSummary,
  Accordion as MuiAccordion,
  Box,
  CardMedia,
} from "@mui/material";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import Translate from "../../../../../modules/Core/Components/Translate";

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  borderTop: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor: "transparent",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const Title = styled(Typography)(({ theme }) => ({
  fontSize: "16px",
  color: "#000",
  fontWeight: "500",
  "& i": {
    fontSize: "20px",
    marginRight: "8px",
  },
}));

export default function TermsAndConditions(props) {
  const { component } = props;
  const theme = useTheme();
  const { slider } = component.getData("default", {});
  const devHelper = component.getHelpers("dev");
  const img_version = component.getHelpers("value").getValue("images-version");
  const filesUrl = component.getHelpers("env").get("files_url");
  const [section, setSection] = React.useState(0);

  const links = [
    {
      title: "There is no 'I' but 'We'",
      desc: "We work as a team in order to offer the best product and service solutions to our customers (internal or external).",
    },
    {
      title: "Always be honest",
      desc: "We treat our customers with respect and in a friendly way. We build trust through sincerity which is the foundation of our business relationships.",
    },
    {
      title: "Show Respect",
      desc: "We show and operate in a respectful manner with our team, customers and suppliers.",
    },
    {
      title: "We do Ethical business",
      desc: "We only perform under ethical standards, always aiming to enhance company’s value using ethical means.",
    },
    {
      title: "Do the Right Thing",
      desc: "Integrity is the foundation of everything that we do.",
    },
    {
      title: "Set the Bar High",
      desc: "We always strive to set the highest standards of quality in everything that we do.",
    },
    {
      title: "Work with Passion",
      desc: "We passionately nurture ideas, inspire excellence and find creative ways to eliminate obstacles for cultivating growth.",
    },
    {
      title: "Be Innovative",
      desc: "We encourage the development of new ideas which motivate action, and we constantly strive to exceed expectations.",
    },
    {
      title: "We Care about Our People",
      desc: "People are the spine of our organization. As we continue to grow, we should provide the means for the development of our people as well.",
    },
    {
      title: "We Care about the Environment",
      desc: "We run our business with respect to the environment and to the next generations.",
    },
  ];

  const handleChange = (panel) => (event, newExpanded) => {
    setSection(newExpanded ? panel : false);
  };

  return (
    <Container
      sx={{
        px: 2,
        my: 3,
      }}
      maxWidth="md"
    >
      {slider && (
        <Box>
          <CardMedia
            component="img"
            alt={`${devHelper.getObjectValue(slider, "_values.name")}`}
            sx={{
              width: { xs: "100%", md: "400px" },
              mx: "auto",
              borderRadius: 12,
            }}
            image={`${filesUrl}/sliders/desktop/${devHelper.getObjectValue(
              slider,
              "_values.slug"
            )}.jpg?v=${img_version}`}
          />
          <Box sx={{ p: 2 }}>
            <Typography
              variant="h5"
              align="left"
              sx={{
                fontSize: 20,
                fontWeight: 600,
                color: "#b52524",
              }}
            >
              {devHelper.getObjectValue(slider, "_values.name")} - THE 10
              COMMANDMENTS OF TASTICO
            </Typography>
            <Typography
              align="left"
              sx={{
                fontSize: 18,
                color: "#000",
                fontWeight: 400,
              }}
            >
              <Translate
                entity="literal"
                eslug={devHelper.getObjectValue(slider, "_values.slug")}
                scope="default"
                slug="default"
                auto
              />
            </Typography>
          </Box>
          {links.map((link, index) => {
            const { title, desc } = link;
            return (
              <Accordion
                key={`links-${index}`}
                expanded={section === index}
                onChange={handleChange(index)}
              >
                <AccordionSummary sx={{ py: 1.5 }}>
                  <Title
                    variant="h2"
                    sx={{
                      ...{ fontSize: { xs: 18, sm: 20, md: 22 } },
                      color: "#b52524",
                    }}
                  >
                    {title}
                  </Title>
                </AccordionSummary>
                <AccordionDetails
                  sx={{
                    px: { xs: 2, md: 5 },
                    "& h5": {
                      fontWeight: 400,
                      mt: 0,
                    },
                    "& h4": {
                      mt: 0,
                    },
                  }}
                >
                  <Typography
                    component="h3"
                    sx={{
                      ...theme.custom.titles.description,
                    }}
                  >
                    {desc}
                  </Typography>
                </AccordionDetails>
              </Accordion>
            );
          })}
        </Box>
      )}
    </Container>
  );
}
