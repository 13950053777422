import React from "react";
import { styled } from "@mui/material/styles";
import {
  SwipeableDrawer,
  Link,
  Accordion as MuiAccordion,
  AccordionSummary as MuiAccordionSummary,
  AccordionDetails as MuiAccordionDetails,
  Box,
  IconButton,
  CardMedia,
  Typography,
} from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  "&:not(:last-child)": {
    borderBottom: 0,
    m: 0,
  },
  "&:before": {
    display: "none",
  },
  "& .MuiButtonBase-root": { padding: 0 },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={
      <i
        className="fa-solid fa-chevron-down"
        style={{ fontSize: "18px", color: "#000" }}
      ></i>
    }
    {...props}
  />
))(({ theme }) => ({
  border: "none",
  p: 0,
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(-90deg)",
    border: "none",
    p: 0,
  },
  "& .MuiAccordionSummary-content": {
    border: "none",
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: "0 16px",
}));

export default function MenuDrawer(props) {
  const { component } = props;
  const devHelper = component.getHelpers("dev");

  const {
    mainMenu = [],
    categories = [],
    rep_categories = [],
    products = {},
    recipes = [],
  } = component.getData("default", {});

  const theme = useTheme();

  //css

  const myTitle = {
    color: "#b52524",
    fontSize: 18,
    fontWeight: 500,
  };

  const myLink = {
    color: "#000",
    fontSize: 16,
    textTransform: "none",
    padding: 0,
    textDecoration: "none",
    fontWeight: 500,
    display: "block",
    mt: 1,
  };

  const mainButton = {
    fontSize: 16,
    textTransform: "uppercase",
    textDecoration: "none",
    fontWeight: 600,
    color: "#000",
  };

  return (
    <SwipeableDrawer
      anchor="left"
      open={component.getData("default.opened", false)}
      onClose={() => component.toggle()}
      onOpen={() => {}}
      sx={{
        zIndex: (theme) => theme.zIndex.drawer + 2,
        "& .MuiDrawer-paper": {
          p: 0,
          overflowY: "scroll",
          scrollbarWidth: "none",
          msOverflowStyle: "none",
          width: { xs: "100%", sm: "500px" },
        },
        "& .MuiDrawer-paper::-webkit-scrollbar": {
          display: "none",
        },
      }}
      transitionDuration={{ enter: 500, exit: 500 }}
      swipeAreaWidth={0}
    >
      <Box
        sx={{
          width: "100%",
          position: "relative",
        }}
      >
        <IconButton
          aria-label="close"
          onClick={() => component.toggle()}
          sx={{
            position: "absolute",
            cursor: "pointer",
            right: "24px",
            top: "50%",
            transform: "translate(0,-50%)",
            fontSize: "22px",
            color: "#000",
          }}
        >
          <CloseIcon />
        </IconButton>
        <Box
          sx={{
            display: "flex",
            px: 3,
            justifyContent: "center",
            height: {
              xs: theme.appbar_height.mobile,
              md: theme.appbar_height.desktop,
            },
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              width: { xs: "75px", md: "85px" },
            }}
            component={RouterLink}
            to="/"
            onClick={() => component.toggle()}
          >
            <CardMedia
              component="img"
              image="/images/logos/logo.png"
              alt="Tastico Logo"
              title="Tastico Home"
              sx={{
                maxWidth: "100%",
                width: "inherit",
              }}
            />
          </Box>
        </Box>
      </Box>
      <Box sx={{ p: 3 }}>
        {mainMenu.map((menu) => {
          const { slug, name, url, menu_template } = menu;

          return (
            <Box key={`mobile-main-menu-root-${slug}`}>
              {menu_template ? (
                <Accordion elevation={0} disableGutters>
                  <AccordionSummary>
                    <Link
                      sx={{
                        ...mainButton,
                      }}
                      component={RouterLink}
                      to={url}
                    >
                      {name}
                    </Link>
                  </AccordionSummary>
                  {menu_template === "products" && (
                    <AccordionDetails>
                      {categories.map((cat, i) => {
                        const { name, slug } = devHelper.getObjectValue(
                          cat,
                          "_values"
                        );

                        return (
                          <Box key={slug}>
                            <Typography component="h2" sx={{ ...myTitle }}>
                              {name}
                            </Typography>
                            <Box
                              sx={{
                                width: 35,
                                height: 2,
                                bgcolor: "#b52524",
                                mb: 1,
                              }}
                            />
                            <ul
                              style={{
                                margin: "0px",
                                padding: "0px",
                                listStyleType: "none",
                                marginBottom: "16px",
                              }}
                            >
                              {devHelper
                                .getObjectValue(products, "data")
                                .filter((pr) =>
                                  pr._values.categories.some(
                                    (cat) => cat.item._values.slug === slug
                                  )
                                )
                                .map((pr, index) => {
                                  const { slug, name } =
                                    devHelper.getObjectValue(pr, "_values");
                                  return (
                                    <li key={`product-${slug}`}>
                                      <Link
                                        component={RouterLink}
                                        to={`/product/${slug}`}
                                        onClick={() => component.toggle()}
                                        sx={{
                                          ...myLink,
                                          mt: index === 0 ? "4px" : 1,
                                        }}
                                      >
                                        {name}
                                      </Link>
                                    </li>
                                  );
                                })}
                            </ul>
                          </Box>
                        );
                      })}
                    </AccordionDetails>
                  )}
                  {menu_template === "recipes" && (
                    <AccordionDetails>
                      {rep_categories.map((cat, i) => {
                        const { name, slug } = devHelper.getObjectValue(
                          cat,
                          "_values"
                        );

                        return (
                          <Box key={slug}>
                            <Typography component="h2" sx={{ ...myTitle }}>
                              {name}
                            </Typography>
                            <Box
                              sx={{
                                width: 35,
                                height: 2,
                                bgcolor: "#b52524",
                                mb: 1,
                              }}
                            />
                            <ul
                              style={{
                                margin: "0px",
                                padding: "0px",
                                listStyleType: "none",
                                marginBottom: "16px",
                              }}
                            >
                              {recipes
                                .filter((rec) =>
                                  rec._values.rec_categories.some(
                                    (cat) => cat.item._values.slug === slug
                                  )
                                )
                                .map((rec, index) => {
                                  const { slug, name } =
                                    devHelper.getObjectValue(rec, "_values");
                                  return (
                                    <li key={`recipe-${slug}`}>
                                      <Link
                                        component={RouterLink}
                                        to={`/recipe/${slug}`}
                                        onClick={() => component.toggle()}
                                        sx={{
                                          ...myLink,
                                          mt: index === 0 ? "4px" : 1,
                                        }}
                                      >
                                        {name}
                                      </Link>
                                    </li>
                                  );
                                })}
                            </ul>
                          </Box>
                        );
                      })}
                    </AccordionDetails>
                  )}
                  {menu_template === "about-us" && (
                    <AccordionDetails>
                      <ul
                        style={{
                          margin: "0px",
                          padding: "0px",
                          listStyleType: "none",
                          marginBottom: "16px",
                        }}
                      >
                        {mainMenu
                          .find((menu) => menu.slug === "about-us")
                          ["sub-menu"].map((menu, index) => {
                            const { title, url, slug } = menu;

                            return (
                              <li key={slug}>
                                <Link
                                  component={RouterLink}
                                  to={url}
                                  onClick={() => component.toggle()}
                                  sx={{
                                    ...myLink,
                                    mt: index === 0 ? "4px" : 1,
                                  }}
                                >
                                  {title}
                                </Link>
                              </li>
                            );
                          })}
                      </ul>
                    </AccordionDetails>
                  )}
                </Accordion>
              ) : (
                <Box sx={{ mt: 1.5, mb: 3 }}>
                  <Link
                    underline="none"
                    sx={{ ...mainButton }}
                    onClick={() => {
                      component.toggle();
                      component
                        .getComponents()
                        .findById("contact-us")
                        .first()
                        .togleDialog(true);
                    }}
                  >
                    {name}
                  </Link>
                </Box>
              )}
            </Box>
          );
        })}
      </Box>
    </SwipeableDrawer>
  );
}
