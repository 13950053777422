import React from "react";
import { Box } from "@mui/material";
import NavBar from "../../../Components/Widgets/Navbar";
import MenuDrawer from "../../../Components/Widgets/MenuDrawer";
import TopBannersSlider from "../../../Components/Widgets/Sliders/TopBannersSlider";
import WhoWeAre from "../../../Components/Widgets/Sliders/WhoWeAre";
import Recipes from "../../../Components/Widgets/Sliders/Recipes";
import Products from "../../../Components/Widgets/Sliders/Products";
import Footer from "../widgets/footer";
import { useTheme } from "@mui/material/styles";

const ExportDefault = (props) => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        minHeight: "100vh",
      }}
    >
      <NavBar
        {...{
          ...props,
          ...{},
        }}
      />
      <Box
        sx={{
          height: {
            xs: theme.appbar_height.mobile,
            md: theme.appbar_height.desktop,
          },
        }}
      />
      <MenuDrawer {...props} />
      <TopBannersSlider
        {...{
          ...props,
          ...{
            fscope: "home-top-slider",
            bottom: "35%",
            left: "3%",
            text_color: "#fff",
            textMaxWidthDesktop: "500px",
            textMaxWidthMobile: "500px",
            lazyLoading: true,
          },
        }}
      />
      <WhoWeAre
        {...{
          ...props,
          ...{
            fscope: "who-we-are",
          },
        }}
      />
      <Products
        {...{
          ...props,
        }}
      />
      <Recipes
        {...{
          ...props,
        }}
      />
      <Footer {...props} />
    </Box>
  );
};

export default ExportDefault;
