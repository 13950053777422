import { Component } from "../../../modules/Core/Component";
import templateDefault from "../../templates/default/widgets/product/first_section";
import Services from "../../Services/Services";
import axios from "axios";

export default class Product extends Component {
  template = templateDefault;
  id = "product";
  group = "wishlist,order";

  onLoad(data) {
    super.onLoad(data);

    this.setProduct();
  }

  setProduct() {
    //Get product
    Services.get("hook").then(([service]) => {
      service
        .exec("product", {
          params: { slug: this.getPage().getParam("slug", "") },
        })
        .then((response) => {
          const product = response.getData() || {};
          const devHelper = this.getHelpers("dev");

          this.getApp().updateWindowTitle(
            `${devHelper.getObjectValue(
              product,
              "_values.name"
            )} | ${this.ucfirst("window-title")}`
          );

          this.getProductFiles(
            devHelper.getObjectValue(product, "_values.slug")
          );

          this.setData({
            "default.product": product,
          });
        });
    });
  }

  getProductFiles(slug) {
    axios
      .get(
        `https://files.automatapp.com/listing/tastico/products/images/${slug}`
      )
      .then((res) => {
        this.setData({
          "default.images": res.data.sort(),
        });
      });

    return this;
  }
}
