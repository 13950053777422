import { Component } from "../../../modules/Core/Component";
import templateDefault from "../../templates/default/widgets/recipes/recipes";
import Services from "../../Services/Services";

export default class Recipes extends Component {
  template = templateDefault;

  onLoad(data) {
    super.onLoad(data);

    this.getProducts();
  }

  getProducts() {
    Services.get("hook").then(async ([hookService]) => {
      try {
        const hookService1 = await hookService.exec("recipes", {
          params: { pageAmount: 1000 },
        });
        const recipes = hookService1.getData() || [];

        this.setData({
          "default.recipes": recipes,
        });
      } catch (e) {
        console.log(e);
      }
    });
  }
}
