import { Component } from "../../../modules/Core/Component";
import templateDefault from "../../templates/default/widgets/recipe/first_section";
import Services from "../../Services/Services";

export default class Recipe extends Component {
  template = templateDefault;
  id = "recipe";

  onLoad(data) {
    super.onLoad(data);

    this.setRecipe();
  }

  setRecipe() {
    //Get product
    Services.get("hook").then(([service]) => {
      service
        .exec("recipe", {
          params: { slug: this.getPage().getParam("slug", "") },
        })
        .then((response) => {
          const recipe = response.getData() || {};
          const devHelper = this.getHelpers("dev");

          this.getApp().updateWindowTitle(
            `${devHelper.getObjectValue(
              recipe,
              "_values.name"
            )} | ${this.ucfirst("window-title")}`
          );

          this.setData({
            "default.recipe": recipe,
          });
        });
    });
  }
}
