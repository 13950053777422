import React from "react";
import {
  Box,
  Typography,
  Link,
  Grid,
  Container,
  Button,
  TextField,
  CardMedia,
} from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";
import { Link as RouterLink } from "react-router-dom";

function DevelopedBy() {
  return (
    <Box sx={{ bgcolor: "#e8e8e8", pt: 2, pb: { xs: "60px", md: 2 } }}>
      <Typography variant="body2" align="center" sx={{ color: "#818181" }}>
        {"Developed by "}
        <Link
          color="inherit"
          href="https://www.automatapp.com/"
          underline="none"
          sx={{ fontWeight: 600 }}
        >
          Automatapp
        </Link>
        {` ${new Date().getFullYear()}`}
      </Typography>
    </Box>
  );
}

const FooterTitle = styled(Typography)(({ theme }) => ({
  fontSize: "24px",
  color: "#fff",
  fontWeight: "600",
  marginBottom: "15px",
}));

export default function StickyFooter(props) {
  const { component } = props;
  const theme = useTheme();

  const myLink = {
    textDecoration: "none",
    color: "#fff",
    marginBottom: "16px",
    display: "flex",
    alignItems: "center",
    fontSize: "20px",
    fontWeight: 500,
    "& i": {
      minWidth: 45,
      fontSize: "30px",
    },
    "& div": {
      textAlign: "center",
      display: "inline-flex",
    },
    "&:hover": {
      color: "#d1d1d1",
    },
  };

  return (
    <Box
      component="footer"
      className="exclude-from-print"
      sx={{
        mt: "auto",
        backgroundColor: "#0b6a76",
      }}
    >
      <Container maxWidth="lg" sx={{ pb: 1, pt: 4 }}>
        <Grid
          container
          sx={{
            alignItems: "stretch",
          }}
          rowSpacing={{ xs: 3 }}
          columnSpacing={{ xs: 6 }}
        >
          <Grid item xs={12} sm={6} lg={5}>
            <FooterTitle>Νewsletter</FooterTitle>
            <Box
              component="form"
              onSubmit={(e) => {
                e.preventDefault();
                component
                  .getApp()
                  .newsletterSubscribe(
                    component.getData("default.subscribe.email")
                  );

                component.setData({ "default.subscribe.email": "" });
              }}
              sx={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Typography
                component="h1"
                sx={{
                  fontSize: 16,
                  color: "#fff",
                }}
              >
                Subscribe to our newsletter and be the first to know about our
                new offers
              </Typography>
              <TextField
                variant="outlined"
                margin="normal"
                fullWidth
                id="email"
                type="text"
                placeholder="Enter your email..."
                onChange={(e) =>
                  component.setData({
                    "default.subscribe.email": e.target.value,
                  })
                }
                value={component.getData("default.subscribe.email", "")}
                sx={{
                  borderRadius: 50,
                  fontSize: 16,
                  bgcolor: "#fff",
                  "& > *": {
                    borderRadius: 50,
                    height: "48px",
                  },
                  input: {
                    px: 4,
                    color: "#000",
                    "&::placeholder": {
                      opacity: 0.8,
                    },
                  },
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      border: "none",
                    },
                  },
                }}
              />
              <Button
                sx={{
                  ...theme.custom.buttons.button2,
                  width: "150px",
                  height: "46px",
                  mt: 1,
                  mx: "auto",
                }}
                type="submit"
              >
                Subscribe
              </Button>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} lg={3}>
            <FooterTitle>Contact Us</FooterTitle>
            <Link
              sx={{ ...myLink }}
              href={"mailto:" + component.trans("shop-email")}
              target="_blank"
            >
              <Box>
                <i className="fa-light fa-circle-envelope" />
              </Box>
              {component.trans("shop-email")}
            </Link>
            {/* <Link
              sx={{ ...myLink }}
              href={"tel:" + component.trans("phone-number")}
            >
              <Box>
                <i className="fa-light fa-circle-phone" />
              </Box>
              {component.trans("phone-number")}
            </Link> */}
            <Button
              sx={{
                ...myLink,
                ...{
                  p: 0,
                  textTransform: "none",
                  color: "#fff",
                  "&:hover": {
                    bgcolor: "transparent",
                    color: "#d1d1d1",
                  },
                },
              }}
              disableRipple
              onClick={() =>
                component
                  .getComponents()
                  .findById("contact-us")
                  .first()
                  .togleDialog(true)
              }
            >
              <Box>
                <i className="fa-light fa-comments-question-check" />
              </Box>
              Contact Form
            </Button>
          </Grid>
          <Grid
            item
            xs={12}
            lg={4}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Button
              sx={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                width: { xs: "160px", md: "180px" },
                outline: "none",
                p: 0,
                mx: 2,
                "&:hover": {
                  bgcolor: "transparent",
                },
              }}
              disableRipple
              component={RouterLink}
              to="/"
            >
              <CardMedia
                component="img"
                image="/images/logos/logo.png"
                alt="Tastico Logo"
                title="Tastico Home"
                sx={{
                  maxWidth: "100%",
                  width: "inherit",
                }}
              />
            </Button>
          </Grid>
        </Grid>
        <Typography
          sx={{
            fontSize: 14,
            color: "#fff",
            mt: 3,
            textAlign: { xs: "center", lg: "right" },
          }}
        >
          {`Copyright © Tastico ${new Date().getFullYear()}`}
        </Typography>
      </Container>
      <DevelopedBy />
    </Box>
  );
}
