import React from "react";
import { Box } from "@mui/material";
import NavBar from "../../../Components/Widgets/Navbar";
import MenuDrawer from "../../../Components/Widgets/MenuDrawer";
import AboutUs from "../../../Components/Widgets/AboutUs";
import Footer from "../widgets/footer";
import { useTheme } from "@mui/material/styles";

const ExportDefault = (props) => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        minHeight: "100vh",
      }}
    >
      <NavBar
        {...{
          ...props,
          ...{},
        }}
      />
      <Box
        sx={{
          height: {
            xs: theme.appbar_height.mobile,
            md: theme.appbar_height.desktop,
          },
        }}
      />
      <MenuDrawer {...props} />
      <AboutUs
        {...{
          ...props,
          ...{ slug: "who-we-are-1" },
        }}
      />
      <Footer {...props} />
    </Box>
  );
};

export default ExportDefault;
