import App from "../../modules/Core/Components/App";
import template from "../templates/app";
import Services from "../Services/Services";

export default class MainApp extends App {
  template = template;

  onLoad(data) {
    super.onLoad(data);
  }

  getOnResizeResolusion(width) {
    if (width < 500) {
      return "mobile";
    }

    if (width >= 500) {
      return "default";
    }
  }

  onNotAllowedResponse(r, err) {
    if (this.getPage().isFullAuth()) {
      this.getPage().logout();
    } else {
      this.getHelpers("auth").clear();
    }
  }

  newsletterSubscribe(email) {
    Services.get("hook").then(async ([hookService]) => {
      const response = await hookService.post("subscribe", {
        email,
      });
      if (response.isValid()) {
        const data = response.getData();
        const valid = data === "ok";
        if (valid) {
          this.getComponents()
            .findById("main-message")
            .first()
            .setData({
              message: {
                text: this.ucfirst("subscribed"),
                type: "success",
                duration: 2000,
              },
            });
        }
      }
    });
  }

  priceFormater(price) {
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
      maximumFractionDigits: 2,
      minimumFractionDigits: 0,
    }).format(price);
  }

  priceFormaterDecimal(price) {
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
      maximumFractionDigits: 2,
      minimumFractionDigits: 2,
    }).format(price);
  }

  updateWindowTitle(title) {
    this.getPage().setWindowTitle(title);

    // window.dataLayer.push({
    //   event: "pageviewCustomEvent",
    //   pagePath: window.location.pathname,
    //   pageTitle: title,
    // });
    return this;
  }
}
