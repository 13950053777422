import React from "react";
import { useTheme } from "@mui/material/styles";
import {
  Box,
  CardMedia,
  Typography,
  useMediaQuery,
  Card,
  CardContent,
  Button,
  Grid,
  Container,
} from "@mui/material";
import { Link as RouterLink } from "react-router-dom";

export default function ProductsSlider(props) {
  const { component } = props;
  const theme = useTheme();
  const view = useMediaQuery(theme.breakpoints.up("md")) ? "desktop" : "mobile";

  const devHelper = component.getHelpers("dev");
  const filesUrl = component.getHelpers("env").get("files_url");
  const img_version = component.getHelpers("value").getValue("images-version");
  const { categories = [], products = {} } = component.getData("default", {});

  return (
    <Box sx={{ py: 8, bgcolor: "#b52524" }}>
      <Container>
        <Typography
          component="h2"
          variant="h2"
          align="center"
          sx={{
            ...theme.custom.titles.title,
            mb: 2,
            color: "#fff",
            mx: 4,
          }}
        >
          Our Products
        </Typography>
        {categories.map((cat, i) => {
          const { name, slug } = devHelper.getObjectValue(cat, "_values");

          return (
            <Box key={slug}>
              <Typography
                component="h2"
                sx={{
                  ...theme.custom.titles.subtitle,
                  fontSize: { xs: 24, sm: 28 },
                  color: "#fff",
                }}
              >
                {name}
              </Typography>
              <Box
                sx={{
                  width: 45,
                  height: 2,
                  bgcolor: "#fff",
                  mb: 3,
                }}
              />
              <Grid container spacing={2}>
                {devHelper
                  .getObjectValue(products, "data")
                  .filter((pr) =>
                    pr._values.categories.some(
                      (cat) => cat.item._values.slug === slug
                    )
                  )
                  .map((pr, index) => {
                    const { slug, name } = devHelper.getObjectValue(
                      pr,
                      "_values"
                    );
                    return (
                      <Grid key={slug} item xs={12} sm={6} md={4}>
                        <Card
                          sx={{
                            borderRadius: "20px",
                            height: "100%",
                            position: "relative",
                            bgcolor: "transparent",
                            width: "100%",
                            textDecoration: "none",
                            textAlign: "center",
                          }}
                          component={RouterLink}
                          to={`/product/${slug}`}
                          elevation={0}
                        >
                          <CardMedia
                            component="img"
                            alt={`${name}-${view}`}
                            sx={{
                              maxWidth: "100%",
                              borderRadius: "20px",
                            }}
                            image={`${filesUrl}/products/images/${slug}/1.jpg?v=${img_version}`}
                          />
                          <CardContent>
                            <Typography
                              variant="h5"
                              align="center"
                              sx={{
                                fontSize: { xs: 20, sm: 24 },
                                fontWeight: 600,
                                color: "#fff",
                                mb: 2,
                                px: 2,
                              }}
                            >
                              {name}
                            </Typography>
                            <Button
                              sx={{
                                ...theme.custom.buttons.button2,
                                width: "180px",
                                height: "46px",
                                mt: 1,
                                mx: "auto",
                              }}
                            >
                              Discover more
                            </Button>
                          </CardContent>
                        </Card>
                      </Grid>
                    );
                  })}
              </Grid>
            </Box>
          );
        })}
      </Container>
    </Box>
  );
}
